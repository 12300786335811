<template>
  <div>
    <div>
      <div
        v-if="loader"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col lg="12">
          <b-card>
            <validation-observer
              ref="notifyForm"
              tag="form"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Title"
                    label-for="title"
                  >
                    <VueEmoji
                      :value="notifyData.title"
                      height="40px"
                      width="300px"
                      @input="onInput"
                    />
                  </b-form-group>

                </b-col>

                <b-col cols="6">
                  <b-media>

                    <b-media-aside>

                      <b-img
                        ref="previewEl"
                        :src="preview"
                        class="ml-1 "
                        width="100"
                      />

                      <!--/ image -->
                    </b-media-aside>
                    <b-media-body class="mt-75 ml-75">
                      <!-- upload button -->
                      <b-button
                        variant="primary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="selectFile()"
                      >
                        Upload Image
                      </b-button>
                      <b-form-file
                        ref="refInputEl"
                        accept=".jpg, .png"
                        :hidden="true"
                        plain
                        @change="changeProfile($event)"
                      />
                      <!--/ upload button -->
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="6">
                  <!-- Name -->

                  <b-form-group
                    label="Body"
                    label-for="body"
                  >
                    <div class="vueEmojiBody">
                      <VueEmoji
                        :value="notifyData.body"
                        width="400px"
                        @input="onInputBody"
                      />

                    </div>

                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-50"
                >
                  <b-button
                    v-if="!loader"
                    variant="primary"
                    class="mr-1"
                    @click="pushNotification()"
                  >
                    Send
                  </b-button>
                  <b-button
                    v-if="loader"
                    variant="primary"
                    disabled
                    class="mr-1"
                  >
                    <b-spinner
                      small
                      type="grow"
                    />
                    Loading...
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VueEmoji from 'emoji-vue'
import previewImage from '@/global-compositions/previewImage'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    VueEmoji,
  },
  mixins: [togglePasswordVisibility, previewImage],
  data() {
    return {
      required,
      loader: false,
      notifyData: [
      ],
      userIds: '',
      pagination: {
        current_page: 1,
      },
      preview: null,
      observer: null,
      limit: 15,
      search: '',
      ad_image: '',
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { formData, setFormData } = handleFormData()
    const allUsers = ref([])

    return {
      getValidationState,
      formData,
      setFormData,
      allUsers,
    }
  },
  computed: {
    filtered() {
      return this.allUsers
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.pagination.current_page < this.pagination.last_page
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },

    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.ad_image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // console.log(this.notifyData.image)
        // eslint-disable-next-line prefer-destructuring
        this.ad_image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    onInput(event) {
      this.notifyData.title = event.data
    },
    onInputBody(event) {
      this.notifyData.body = event.data
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async searchUsers(searchQuery) {
      await axios.get(`users?search=${searchQuery}&role=2`).then(res => {
        if (res.status === 200) {
          this.allUsers = res.data?.data
          this.pagination = res.data.meta
        }
      })
    },
    async infiniteScroll([{ isIntersecting }]) {
      if (isIntersecting) {
        this.pagination.current_page += 1
        axios
          .get(`users?page=${this.pagination.current_page}`)
          .then(response => {
            if (response.data.data.length > 1) {
              response.data.data.forEach(item => this.allUsers.push(item))
              this.limit += 15
            }
          })
      }
    },

    pushNotification() {
      this.$refs.notifyForm.validate().then(success => {
        if (success) {
          this.loader = true
          this.setFormData(this.notifyData)
          this.formData.append('image', this.ad_image)
          axios
            .post(
              'send_notification',
              this.formData,
            )
            .then(res => {
              if (res.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sent Succesfully',
                    icon: 'BellIcon',
                    variant: 'success',
                  },
                })
              }
            })
            .catch(error => {
              if (error.response.status === 500) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Somthing Went Wrong',
                    icon: 'BellIcon',
                    variant: 'error',
                  },
                })
              }
            })
            .finally(() => {
              this.loader = false
              this.notifyData = {}
              this.preview = ''
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.emoji-wysiwyg-editor{
  border: 1px solid #d8d6de !important;
    border-radius: 0.357rem !important;
    height: 39px !important;
    padding-top: 10px !important;

}
.emoji-picker-icon{
  top: 7px !important;
}
.vueEmojiBody .emoji-wysiwyg-editor{
  height: 100px !important;

}
.emoji-wysiwyg-editor :focus{
  border: #30A9FF !important;
    border-bottom-color: #30A9FF !important;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}

</style>
